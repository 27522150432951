
import './App.css';
import HomeScreen from './screens/HomeScreen';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';



const router = createBrowserRouter([
  {
    path: "/",
    
    element: <HomeScreen />,
  },
  {
    path: "*",
    
    element: <HomeScreen />,
  },
  
]);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
