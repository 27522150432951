import React, { useState } from 'react'

import bgHeaderHero from '../assets/images/header-hero-img.png';
import bgAboutHeader from '../assets/images/about-header-img.png';
import amanayLogo from '../assets/icons/amanay-home-logo.png';
import experienceFirstRight from '../assets/images/experience-first-right.png';
import experienceFirstLeft from '../assets/images/experience-first-left.png';
import experienceSecondRight from '../assets/images/experience-second-right.png';
import experienceSecondLeft from '../assets/images/experience-second-left.png';
import '../App.css';
import amanayYan from '../assets/images/amanay-yun.png';
import amanaySin from '../assets/images/amanay-sin-min.png';
import amanayKid from '../assets/images/amanay-kid-min.png';
import amanaySuite from '../assets/images/amanay-suite-min.png';
import galleryA from '../assets/images/gallery-a-min.png';
import galleryB from '../assets/images/gallery-b-min.png';
import galleryC from '../assets/images/gallery-c-min.png';
import galleryD from '../assets/images/gallery-d-min.png';
import galleryE from '../assets/images/gallery-e-min.png';
import galleryF from '../assets/images/gallery-f-min.png';
import galleryG from '../assets/images/gallery-g-min.png';
import galleryH from '../assets/images/gallery-h-min.png';
import galleryI from '../assets/images/gallery-i-min.png';
import galleryJ from '../assets/images/gallery-j-min.png';
import galleryK from '../assets/images/gallery-k-min.png';
import galleryL from '../assets/images/gallery-l-min.png';
import galleryM from '../assets/images/gallery-m-min.png';
import ourRoomShadow from '../assets/images/our-rooms-shadow-min.png';
import ourExperienceShadow from '../assets/images/our-experience-shadow-min.png';
import galleryShadow from '../assets/images/gallery-shadow-min.png';


import backFooter from '../assets/images/back-footer-img.png';
import { Link } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';


function HomeScreen() {

  const [isOpen , setIsOpen]=useState(false);
  const [imageOpen , setImageOpen]=useState(null);
  const [emailController , setEmailController]=useState("");
  const [subjectController , setSubjectController]=useState("");
  const [messageController , setMessageController]=useState("");

  const [isLoading, setIsLoading]=useState(false)


  return (
    <div className='relative'>
       <ToastContainer
          position="top-right"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          />
      {/* loading */}
      {isLoading && <div className='h-screen w-screen fixed z-50 bg-[#0000005c] flex items-center justify-center scr'>
          <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
          </svg>
      </div>}
      {/* header + Hero */}
      <div className="bg-cover bg-center w-screen h-screen relative" style={{ backgroundImage: `url(${bgHeaderHero})` }}>
        {/* header */}
        <div className=' fixed z-40 bg-white w-screen shadow'>
          <div className='container mx-auto md:px-1 px-3 pt-2 flex items-center justify-between pb-2 '>
            {/* logo */}
            <Link to={"/"}>
              <img alt='Amanay Home'   className=' md:h-14 h-14' src={amanayLogo}/>
            </Link>
            {/* menu */}
            <div className='hidden md:flex items-center justify-between '>
              <a className='mx-3 cursor-pointer' href='#about-us'>
                <span className='text-black text-xl hover:text-red-500'>About Us</span>
              </a>
              <a className='mx-3 cursor-pointer' href='#our-rooms'>
                <span className='text-black text-xl hover:text-red-500'>Rooms</span>
              </a>
              <a className='mx-3 cursor-pointer' href='#our-experiences'>
                <span className='text-black text-xl hover:text-red-500'>Experiences</span>
              </a>
              <a className='mx-3 cursor-pointer' href='#gallery'>
                <span className='text-black text-xl hover:text-red-500'>Gallery</span>
              </a>
            </div>
            {/* contact us */}
            <div className=' hidden md:block'>
              <a className='mx-1 cursor-pointer bg-[#0C5F1B] px-6 py-2 ' href='#contact-us'>
                <span className='text-white text-xl'>Contact Us</span>
              </a>
            </div>
            {/* menu mobile */}
            <button className='md:hidden block' onClick={()=>{setIsOpen(true)}}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 hover:text-red-500">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
              </svg>
            </button>
            <div className={`w-screen min-h-96 bg-black absolute top-0 left-0 ${!isOpen?'hidden':""} rounded-b transition`}>
                <div className='flex flex-col my-6 '>
                  <div className='w-full text-right'>
                    <button className='mx-5 ' onClick={()=>{setIsOpen(false)}}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 text-white hover:text-red-500">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                  </div>
                    <a className=' cursor-pointer mx-auto my-4' href='#about-us' onClick={()=>{setIsOpen(false)}}>
                      <span className='text-white text-xl  hover:text-red-500 '>About Us</span>
                    </a>
                    <a className=' cursor-pointer mx-auto my-4' href='#our-rooms' onClick={()=>{setIsOpen(false)}}>
                      <span className='text-white text-xl  hover:text-red-500'>Rooms</span>
                    </a>
                    <a className=' cursor-pointer mx-auto my-4' href='#our-experiences' onClick={()=>{setIsOpen(false)}}>
                      <span className='text-white text-xl  hover:text-red-500'>Experiences</span>
                    </a>
                    <a className=' cursor-pointer mx-auto my-4' href='#gallery' onClick={()=>{setIsOpen(false)}}>
                      <span className='text-white text-xl  hover:text-red-500'>Gallery</span>
                    </a>
                    <a className=' cursor-pointer mx-auto my-4' href='#contact-us' onClick={()=>{setIsOpen(false)}}>
                      <span className='text-white text-xl  hover:text-red-500'>Contact us</span>
                    </a>
                </div>
            </div>
          </div>
        </div>
        {/* Hero */}
        <div className='container mx-auto flex flex-col items-center justify-center min-h-[80vh] w-full'>
            <div>
              <p className='text-white uppercase md:text-[50px] sm:text-[30px] font-normal'>WELCOME TO AMANAY HOME</p>
            </div>
            {/*  */}
            <div className='mt-3'>
              <a className='font-normal mx-1 cursor-pointer bg-[#0C5F1B] px-8 py-2 text-white md:text-[28px]' href='#about-us'>
                <span className='font-normal'>EXPLORE</span>
              </a>
            </div>
        </div>
      </div>
      {/* shadow */}
      <div className='relative '>
        <div className=' absolute bggrad w-screen h-72 -top-36 z-20 '></div>
      </div>

      {/* <div className='w-screen h-96 absolute -bottom-72 z-50' style={{ backgroundImage: `url(${shadowHero})`, backgroundPosition:'center', backgroundSize:'contain' }} ></div> */}
      {/* About Us */}
      <div id='about-us' className="bg-cover bg-center w-screen min-h-screen relative" style={{ backgroundImage: `url(${bgAboutHeader})` }}>
        <div className='container mx-auto flex flex-col items-center justify-center min-h-screen py-40'>
          <div className='bg-[#0C5F1BCF] md:w-3/5 w-11/12 opacity-80 px-5 py-10'>
            <div>
              <p className='text-white text-center font-bold md:text-3xl text-xl'>An authentic and exceptional stay in a Berber village.</p>
            </div>
            {/*  */}
            <div className='mt-6 text-white md:text-xl text-md'>
              <p text-white>Located in the heart of a typical Berber village, it feels as though time has almost stopped, yet it's just a 40-minute drive south of Marrakech. Amanay Home is situated at the foot of the Atlas Mountains, offering stunning views of the Marrakesh plains and the twinkling lights of the city at night. Amidst the calm and tranquility of the delightful Moroccan countryside, you feel light-years away from the bustle of the Djema-el-Fna and the teeming souks of the city.</p>
            </div>
          </div>
        </div>
      </div>
      {/* Our Rooms */}
      <div className='' id='our-rooms'>
        <div className=' container relative mx-auto md:h-36 h-20 text-center flex flex-col items-center justify-center'>
          <p className=' uppercase md:text-5xl text-2xl font-normal'>OUR ROOMS</p>
          <img alt='Amanay Home' className=' md:h-28 absolute z-0 w-full ' src={ourRoomShadow}/>
          {/* <p className='absolute  md:text-[7rem] text-[3rem] z-0 opacity-20'>OUR ROOMS</p> */}
        </div>
        
        {/* Amanay Yan */}
        <div className='bg-[#923E00] border-b-2 border-white'>
          <div className=' mx-auto flex'>
            <div className='flex-1 border-r border-white'>
              <img alt='Amanay Home' className=' w-full   ' src={amanayYan} />
            </div>
            <div className='flex-1 flex border-l border-white  items-center justify-center'>
              <div className='text-white border md:text-2xl border-white px-3 py-2'>
              Amanay Yan
              </div>
            </div>
          </div>
        </div>
        {/* Amanay Sin */}
        <div className='bg-[#C5916B] border-b-2 border-white'>
          <div className=' flex '>
            <div className='flex-1 flex items-center justify-center  border-r  border-white'>
              <div className='text-white md:text-2xl border border-white px-3 py-2'>
              Amanay Sin
              </div>
            </div>
            <div className='flex-1 border-l border-white'>
              <img alt='Amanay Home' src={amanaySin} className='w-full  '/>
            </div>
          </div>
        </div>
        {/* Amanay Krad */}
        <div className='bg-[#923E00] border-b-2 border-white'>
          <div className=' flex'>
            <div className='flex-1 border-r border-white'>
              <img alt='Amanay Home' src={amanayKid} className='w-full  '/>
            </div>
            <div className='flex-1 flex border-l  border-white  items-center justify-center'>
              <div className='text-white md:text-2xl border border-white px-3 py-2'>
              Amanay Krad
              </div>
            </div>
          </div>
        </div>
        {/* Amanay Suite */}
        <div className='bg-[#C5916B] border-b-2 border-white'>
          <div className=' flex'>
            <div className='flex-1 flex  items-center justify-center border-r  border-white'>
              <div className='text-white md:text-2xl border border-white px-3 py-2'>
              Amanay Suite
              </div>
            </div>
            <div className='flex-1 border-l  border-white'>
              <img alt='Amanay Home' src={amanaySuite} className='w-full  '/>
            </div>
          </div>
        </div>
      </div>
      {/* OUR EXPERIENCES */}
      <div className='' id='our-experiences'>
        
        <div className=' container relative mx-auto md:h-36  h-20 text-center flex flex-col items-center justify-center'>
          <p className=' uppercase  md:text-5xl text-2xl font-normal'>OUR EXPERIENCES</p>
          <img alt='Amanay Home' className=' absolute z-0 w-full ' src={ourExperienceShadow}/>
          {/* <p className='absolute  md:text-[7rem] text-[3rem] z-0 opacity-20'>OUR ROOMS</p> */}
        </div>
        {/* first parag */}
        <div className=''>
          <div className='container mx-auto  my-4 relative'>
            <div className='flex'>
              <div className='flex-1 flex  items-center justify-center  mx-4'>
                <div className=' px-3 py-2 md:text-lg sm:text-sm text-[10px] text-justify'>
                  Explore the village and meet its people during inclusive walking tours, where you'll witness terraced farming, ancient irrigation systems, and experience the artistry of a potter shaping clay to create a Tajine. Additionally, learn about the challenges confronting Berber mountain communities and how 'social responsibility' charitable projects are effecting positive change within this specific community. These projects include the establishment of a pre-school education center and the empowerment of women through a weaving co-operative.
                </div>
              </div>
              <div className='flex-1 md:h-[25rem] h-[15rem] relative  mx-4'>
                <img alt='Amanay Home' className='md:h-[15rem] md:w-[15rem] w-[7rem] h-[7rem]  absolute left-[33%] top-0' src={experienceFirstRight}/>
                <img alt='Amanay Home' className='md:h-[15rem] md:w-[15rem] w-[7rem] h-[7rem]  absolute left-0 bottom-0 top-8' src={experienceFirstLeft}/>
              </div>
            </div>
          </div>
        </div>
        {/* Amanay Sin */}
        <div className=''>
          <div className='container mx-auto flex'>
            <div className='flex-1 md:h-[20rem] h-[15rem] relative mx-4'>
                <img alt='Amanay Home' className='md:h-[15rem] md:w-[15rem] w-[7rem] h-[7rem]  absolute right-0 top-8' src={experienceSecondRight}/>
                <img alt='Amanay Home' className='md:h-[15rem] md:w-[15rem] w-[7rem] h-[7rem]  absolute right-[33%] bottom-0 top-0' src={experienceSecondLeft}/>
            </div>
            <div className='flex-1 flex  items-center justify-center  mx-4'>
              <div className=' px-3 py-2 md:text-lg sm:text-sm text-[10px] text-justify'>
                Venture further afield by embarking on hikes through the foothills, visiting the weekly Berber markets, or exploring the breathtaking Ourika Valley. You can also partake in a camel trek or venture out on a quad bike excursion. A stay at Amanay Home presents a distinct opportunity for an immersive discovery of the Berber way of life. Whether you're exploring the village, sharing mint tea, enjoying a meal with Berbers in their homes, or gathering under an ancient olive tree, you'll truly absorb the essence of Berber culture. Come and stay amidst the Berbers for an unforgettable experience.
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* GALLERY */}
      <div className='' id='gallery'>
        <div className='container relative mx-auto md:h-36 h-20 text-center flex flex-col items-center justify-center my-6'>
          <p className=' uppercase md:text-5xl text-2xl font-normal'>GALLERY</p>
          <img alt='Amanay Home' className='  absolute z-0 ' src={galleryShadow}/>
          {/* <p className='absolute  md:text-[7rem] text-[3rem] z-0 opacity-20'>OUR ROOMS</p> */}
        </div>
        {/* line 1 */}
        <div className=''>
          <div className='container mx-auto flex flex-row'>
            <div className='flex-1  '>
              <img onClick={()=>setImageOpen(galleryA)} alt='Amanay Home' src={galleryA} className=' w-full md:p-3 p-1'/>
            </div>
            <div className='flex-1  w-full flex flex-col  items-center justify-center'>
              <div className='flex-1  w-full'>
                <div className='flex flex-row  w-full'>
                  <div className='flex-1'>
                      <img onClick={()=>setImageOpen(galleryB)} alt='Amanay Home' src={galleryB}  className=' w-full md:p-3 p-1'/>
                  </div>
                  <div className='flex-1'>
                      <img onClick={()=>setImageOpen(galleryC)} alt='Amanay Home' src={galleryC}  className=' w-full md:p-3 p-1'/>
                  </div>
                </div>
              </div>
              <div className='flex-1   w-full'>
                <div className='flex flex-row'>
                  <div className='flex-1'>
                      <img onClick={()=>setImageOpen(galleryD)} alt='Amanay Home'  src={galleryD}  className=' w-full md:p-3 p-1'/>
                  </div>
                  <div className='flex-1'>
                      <img onClick={()=>setImageOpen(galleryE)} alt='Amanay Home' src={galleryE}  className=' w-full md:p-3 p-1'/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* line 2 */}
        <div className=' '>
          <div className='container mx-auto flex flex-row'>
            <div className='flex-1'>
                  <img onClick={()=>setImageOpen(galleryF)} alt='Amanay Home' src={galleryF}  className=' w-full md:p-3 p-1'/>
            </div>
            <div className='flex-1'>
                  <img onClick={()=>setImageOpen(galleryG)} alt='Amanay Home' src={galleryG}  className=' w-full md:p-3 p-1'/>
                
            </div>
            <div className='flex-1'>
                  <img onClick={()=>setImageOpen(galleryH)} alt='Amanay Home' src={galleryH}  className=' w-full md:p-3 p-1'/>
            </div>
            <div className='flex-1'>
                  <img onClick={()=>setImageOpen(galleryI)} alt='Amanay Home' src={galleryI}  className=' w-full md:p-3 p-1'/>
            </div>
          </div>
        </div>
        {/* line 3 */}
        <div className=' '>
          <div className='container mx-auto flex flex-row'>
            <div className='flex-1'>
                <img onClick={()=>setImageOpen(galleryJ)} alt='Amanay Home' src={galleryJ}  className=' w-full md:p-3 p-1'/>
            </div>
            <div className='flex-1'>
                  <img onClick={()=>setImageOpen(galleryK)} alt='Amanay Home' src={galleryK}  className=' w-full md:p-3 p-1'/>
            </div>
            <div className='flex-1'>
                <img onClick={()=>setImageOpen(galleryL)} alt='Amanay Home' src={galleryL}  className=' w-full md:p-3 p-1'/>
            </div>
            <div className='flex-1'>
                <img onClick={()=>setImageOpen(galleryM)} alt='Amanay Home' src={galleryM}  className=' w-full md:p-3 p-1'/>
            </div>
          </div>
        </div>
      </div>
      {/* footer */}
      <div id='contact-us' className="bg-cover bg-center w-screen  relative py-9" style={{ backgroundImage: `url(${backFooter})` }}>
        <div className='container mx-auto'>
          <div className='text-white md:text-6xl  text-3xl mt-4 md:text-left text-center'>
          Book your stay !
          </div>
          <div className='md:flex'>
            <div className='md:w-1/2 py-8 px-16'>
              <div className=' mb-3'>
                <p className='text-white text-xl'>Your email adresse</p>
                <input value={emailController} onChange={(v)=>setEmailController(v.target.value)} type='email'  className='bg-[#0C5F1B] w-full h-11 rounded px-3 text-white ' style={{ boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.5)', }}/>
              </div>
              <div className=' mb-3'>
                <p className='text-white  text-xl'>Subject</p>
                <input value={subjectController}  onChange={(v)=>setSubjectController(v.target.value)}  type='text' className='bg-[#0C5F1B] w-full h-11 rounded px-3  text-white' style={{ boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.5)', }} />
              </div>
              <div className=' mb-3'>
                <p className='text-white  text-xl'>Message</p>
                <textarea  value={messageController} onChange={(v)=>setMessageController(v.target.value)}  rows={5}  className='bg-[#0C5F1B] w-full  rounded px-3 text-white ' style={{ boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.5)', }}></textarea>
              </div>
              <div className=' mb-3'>
                <button onClick={async()=>{
                  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                  const isValid = emailPattern.test(emailController);
                  if(emailController===""){
                    toast.error("Please enter your email")
                  }else if(subjectController===""){
                    toast.error("Please enter your subject")
                  }else if(messageController===""){
                    toast.error("Please enter your message")
                  }else if(!isValid){
                    toast.error("Please enter your correct email")
                  }else {
                    // toast.success("nice")
                    setIsLoading(true)
                    
                    try {
                      await axios.post('https://mailapi.amanayhome.com/send-email/',  {
                        // await axios.post('http://localhost:3001/send-email/',  {
                        to:emailController ,
                        subject: subjectController,
                        text: messageController,
                      },{
                        withCredentials: true, // Ensure CORS is enabled
                      });
                      setIsLoading(false)
                      toast.success("Email sent successfully!")
                      setEmailController("");
                      setSubjectController("");
                      setMessageController("");
                      // alert('Email sent successfully!');
                    } catch (error) {
                      setIsLoading(false)
                      toast.error("Error sending email. Please try again.l")
                      console.error('Error sending email:', error.message);
                      // alert('Error sending email. Please try again.');
                    }
                    
                  }
                }}
                 className='bg-white  text-xl w-full rounded h-11  font-bold'>Submit</button>
              </div>
            </div>
            <div className='md:w-1/2 py-8 px-16 md:text-left text-center '>
                <div className='my-3'>
                  <p className='text-white  text-xl '>Email us</p>
                  <a href='mailto:info@amanayhome.com' className='' >
                    <p className='text-white'>info@amanayhome.com</p>
                  </a>
                </div>
                <div className='my-2'>
                  <p className='text-white  text-xl '>Call us</p>
                  <a href='tel:+212661081871' className='' >
                    <p className='text-white'>+212 6 61 08 18 71</p>
                  </a>
                </div>
            </div>
          </div>
          <div className='text-white md:text-right text-center mx-6'>
          @{new Date().getFullYear()}, Powered by <a href='https://aynnaka.ma/' target='_blank' rel="noreferrer" className='font-bold hover:text-red-500'>Aynnaka</a> 
          </div>
        </div>
      </div>
      
      {imageOpen && (
        <div  onClick={() => setImageOpen(null)} className="fixed top-0 z-50 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-80" >
          <img alt='Amanay Home'
            src={imageOpen}
            className="md:min-w-[50%] min-w-[70%] m-12 border-2 border-white"
          />
          <button
            onClick={() => setImageOpen(null)}
            className="text-white absolute top-36 right-4 cursor-pointer"
          >
            Close
          </button>
        </div>
      )}
      
    </div>
  )
}

export default HomeScreen